import get from 'lodash-es/get';
import set from 'lodash-es/set';

import { InvitedMembers } from '../models/administration';
import { Actor, ActorReduced, AutocompleteOrganization, BlockChainTransaction, Chat, DashboardEntity, EventCard, Favorite, GenericElement, GenericWrapper, GenericWrapperArray, LoginWrapper, OrganizationExtended, PaginatedWrapper, Place, RecordDocument, RecordEntity, Usage, User, UserWrapper } from '../models/main';


export class FormatHttp {

  constructor() {
  }

  // FORMATEADORES COMUNES: son utilizados por los de entidades concretas

  // JSON API manda el id y el type fuera de los atributos. Esta funcion junta todo y lo devuelve
  static fixJSONAPI<T>(data: GenericElement<T>, conf: { meta: boolean } = { meta: false }): T {
    // @ts-ignore
    data.attributes.id = data.id;
    // @ts-ignore
    data.attributes.type = data.attributes.type || data.type;
    return conf.meta ? { ...data.attributes, ...data.meta } : data.attributes;
  }

  // Devuelve el data con los objetos procesados pero sin tener que popular nada
  static formatResponseArray<T>(response: GenericWrapperArray<T>): T[] {
    return response.data.map(elem => FormatHttp.fixJSONAPI<T>(elem));
  }

  static populateResponse<T>(response: GenericWrapper<T>, entities: string[][], conf: { meta: boolean } = { meta: false }): T {
    if (Array.isArray(response.data)) throw new Error('El servicio ha retornado un array, parece que deberias usar populateResponseArray');
    const data = FormatHttp.fixJSONAPI(response.data, conf);

    for (const entity of entities) {
      FormatHttp.populateNestedArrayEntities(data, entity, response, conf);
    }
    return data;
  }

  // Si necesitas paginacion usa formatPaginatedList
  static populateResponseArray<T>(response: GenericWrapperArray<T>, entities: string[][], conf: { meta: boolean } = { meta: false }): T[] {
    return response.data.map(dataElem => {
      const elem = FormatHttp.fixJSONAPI(dataElem, conf);

      for (const entity of entities) {
        FormatHttp.populateNestedArrayEntities<T>(elem, entity, response, conf);
      }
      return elem;
    });
  }

  // Recorre la respuesta de la peticion http para popular la entidad dada. Al ser un array de strings, popula el ultimo elemento del array e itera el resto.
  // Ejemplo: Entity = ['campo1.array1', 'campo2'] --> Popula el campo2 que esta dentro de un array (array1) que a su vez esta dentro de otro campo (campo1)
  static populateNestedArrayEntities<T>(data: any, entity: string[], response: GenericWrapper<T> | GenericWrapperArray<T>, conf: { meta: boolean }) {
    const newData = get(data, entity[0]);
    // Podrían pedir atributos opcionales como la org para un guest
    if (!newData) return;

    if (entity.length === 1) {
      // El funcionamiento es similar si tratamos una entidad que si es un array de entidades
      if (Array.isArray(newData)) {
        for (let i = 0; i < newData.length; i++) {
          const resource = response.included.find(include => include.id === newData[i].id);
          if (resource != null) {
            FormatHttp.fixJSONAPI(resource);
            const res = conf.meta ? { ...resource.attributes, ...resource.meta } : resource.attributes;
            set(data, entity[0] + '.' + i, res);
          }
        }
      } else {
        const resource = response.included.find(include => include.id === newData.id);
        if (resource != null) {
          FormatHttp.fixJSONAPI(resource);
          const res = conf.meta ? { ...resource.attributes, ...resource.meta } : resource.attributes;
          set(data, entity[0], res);
        }
      }
    } else {
      const newEntity = entity.slice(1); // Para hacer la llamada recursiva sin el elemento del array procesado

      return newData.forEach((elem: any) => this.populateNestedArrayEntities(elem, newEntity, response, conf));
    }
  }

  static formatPaginatedList<T>(response: GenericWrapperArray<T>, entities: string[][], conf: { meta: boolean } = { meta: false }): PaginatedWrapper<T> {
    return { data: FormatHttp.populateResponseArray(response, entities, conf), total: response.meta.total };
  }

  static populateBlockchain(response: GenericWrapper<unknown> | GenericWrapperArray<unknown>, data: Record<string, BlockChainTransaction>) {
    Object.keys(data).forEach(blockchainNetwork => {
      const id = data[blockchainNetwork].upload?.id || data[blockchainNetwork].update?.id || data[blockchainNetwork].registration?.id;
      const elem = response.included.find(include => include.id === id);
      if (elem) {
        data[blockchainNetwork] = FormatHttp.fixJSONAPI(elem);
      } else {
        console.error(`Elemento no encontrado en el includes. Id: ${id}, Red: ${blockchainNetwork}`);
        data[blockchainNetwork] = {} as BlockChainTransaction;
      }
    });
  }

  // FORMATEADORES DE ENTIDADES: cada entidad de negocio que necesite un procesamiento especifico

  static formatLogin(response: GenericWrapper<any>): LoginWrapper {
    // Metodo inspirado en formatUserMe solo que aqui recibo una entidad mas llamada "entity" que representa el usuario logado. La idea es que tenga el objeto de BC populado para que sea equivalente al GET /user
    const data = FormatHttp.populateResponse(response, [['entity'], ['entity.organization']]).entity;
    if (data.organization) FormatHttp.populateBlockchain(response, data.organization.blockchain);
    return { user: data, organization: data.organization, token: response.data.attributes.token };
  }

  static formatSignUp(response: GenericWrapper<any>): LoginWrapper {
    const formatSignUp: any = { token: response.data.attributes.token, validationCode: response.data.meta?.validationCode };

    Object.keys(response.data.attributes).forEach(el => {
      if (response.data.attributes[el]?.id) {
        // los includes
        const resource = response.included.find((include: any) => include.id === response.data.attributes[el]?.id)!;
        formatSignUp[el] = resource.attributes;
        formatSignUp[el].id = resource.id;
      }
    });
    return formatSignUp;
  }

  static formatUserMe(response: GenericWrapper<User>): UserWrapper {
    const data = FormatHttp.populateResponse(response, [['organization']]);
    if (data.organization) FormatHttp.populateBlockchain(response, data.organization.blockchain);
    return { user: data, organization: data.organization };
  }

  static formatDashboardEntityList(response: any): PaginatedWrapper<DashboardEntity> {
    const dashboardElements = FormatHttp.populateResponseArray<DashboardEntity>(response, [['record'], ['record.organization'], ['record.createdBy']], { meta: true });
    return { data: dashboardElements, total: response.meta.total };
  }

  static formatRecordList(response: any): PaginatedWrapper<RecordEntity> {
    const dashboardElements = FormatHttp.populateResponseArray<RecordEntity>(response, [['record']], { meta: true });
    return { data: dashboardElements, total: response.meta.total };
  }

  static formatInvitedMembers(response: any): PaginatedWrapper<InvitedMembers> {
    const invitedMembers = FormatHttp.populateResponseArray<InvitedMembers>(response, [['user']]);
    invitedMembers.forEach((member: any) => {
      member.status = response.included.find((e: { id: string }) => e.id === member.user?.id)?.attributes?.status || '';
    });
    return { data: invitedMembers, total: response.meta.total };
  }

  static formatTemplatesList(response: any) {
    return response.data.map((el: any) => ({ name: el.attributes.name, organization: el.attributes.organization || null, id: el.id }));
  }

  static formatCanvasList(response: any) {
    const res = response.data.attributes;
    res.nodes.forEach((node: any) => {
      node.displayName = response.included.find((e: { id: string }) => e.id === node.resource.id).attributes.displayName;
    });
    return res;
  }

  static formatCanvasNode(response: any) {
    if (!response || !response.included || !response.data.attributes) return null;
    const node = response.data.attributes;
    node.displayName = response.included.find((e: { id: string }) => e.id === node.resource.id).attributes.displayName;
    return node;
  }

  static formatActor(response: any): Actor {
    return { ...response.data.attributes, id: response.data.id, record: response.data.attributes.record.id, type: response.data.attributes.record.type, organization: response.data.attributes.record.attributes?.organization?.id, meta: response.data.meta };
  }

  static formatPlace(response: any): Place {
    return { ...response.data.attributes, id: response.data.id, record: response.data.attributes.record.id, type: response.data.attributes.record.type, organization: response.data.attributes.record.attributes.organization.id };
  }

  static formatOrganizations(response: any): PaginatedWrapper<OrganizationExtended> {
    const listElements = FormatHttp.populateResponseArray<OrganizationExtended>(response, [['createdBy']], { meta: true });

    return { data: listElements, total: response.meta.total };
  }

  static formatActors(response: GenericWrapperArray<ActorReduced>): ActorReduced[] {
    return FormatHttp.populateResponseArray<ActorReduced>(response, [['collaborators', 'user'], ['organization']], { meta: true });
  }


  static formatDocument(response: GenericWrapper<RecordDocument>): RecordDocument {
    const populatedResponse = FormatHttp.populateResponse<RecordDocument>(response, [['versions', 'createdBy'], ['versions', 'createdBy.organization']]);
    populatedResponse.versions.reverse();
    return populatedResponse;
  }

  static formatDocuments(response: GenericWrapperArray<RecordDocument>): PaginatedWrapper<RecordDocument> {
    const populatedResponse = FormatHttp.populateResponseArray<RecordDocument>(response, [['versions', 'createdBy'], ['versions', 'createdBy.organization']]);
    populatedResponse.forEach(doc => doc.versions.reverse());

    return {
      data: populatedResponse,
      total: response.meta.total,
    };
  }

  static formatDocumentDetail(response: GenericWrapper<RecordDocument>): RecordDocument {
    const doc = FormatHttp.populateResponse<RecordDocument>(response, [['versions', 'createdBy'], ['versions', 'createdBy.organization']]);
    doc.versions.reverse().forEach(version => {
      FormatHttp.populateBlockchain(response, version.blockchain);
    });
    return doc;
  }

  static formatChat(response: GenericWrapper<Chat>): Chat {
    return FormatHttp.populateResponse<Chat>(response, [['participants'], ['owner']]);
  }

  static formatChatList(response: GenericWrapperArray<Chat>): PaginatedWrapper<Chat> {
    const populatedResponse = FormatHttp.populateResponseArray<Chat>(response, [['lastMessage.senderUser'], ['record']], { meta: true });
    populatedResponse.forEach(element => element.unread = element.totalMessagesCount - element.read);

    return {
      data: populatedResponse,
      total: response.meta.total,
    };
  }

  static formatFavorites(response: GenericWrapperArray<Favorite>): PaginatedWrapper<Favorite> {
    const populatedResponse = FormatHttp.populateResponseArray<Favorite>(response, [['createdBy']], { meta: true });
    return {
      data: populatedResponse,
      total: response.meta.total,
    };
  }

  static formatAutocomplete(response: any): AutocompleteOrganization[] {
    const data = response.data.attributes.map((autocompleteElement: any) => {
      let mapObject = {};
      if (autocompleteElement.entity.type === 'record-actors') {
        let recordId = '';
        response.included.forEach((includedElement: any) => {
          if (autocompleteElement.entity.id === includedElement.id) recordId = includedElement.attributes.record.id;
        });
        response.included.forEach((includedElement: any) => {
          if (includedElement.id === recordId) mapObject = { ...autocompleteElement, record: includedElement };
        });
      } else {
        mapObject = { ...autocompleteElement };
      }
      return mapObject;
    });
    return data.map((e: any) => ({
      ...e.actorData,
      id: e.entity.id,
      autocompleteType: e.entity.type,
      autocompleteRecord: { id: e.record?.id, alias: e.record?.attributes.alias },
    }));
  }

  static formatUsage(usageElement: any): Usage {
    const usage = usageElement.attributes;
    usage.year = usage.periodMonth.slice(0, 4);
    usage.month = usage.periodMonth.slice(-2);
    usage.periodStartAt = new Date(usage.periodStartAt);
    usage.periodEndAt = new Date(usage.periodEndAt);
    return usage;
  }

  static formatUsages(response: any): PaginatedWrapper<Usage> {
    const usages = response.data.map(FormatHttp.formatUsage);
    return { data: usages, total: response.meta.total };
  }

  static formatEventList(response: GenericWrapperArray<any>): EventCard[] {
    const populatedResponse = FormatHttp.populateResponseArray<EventCard>(response, [['createdBy'], ['createdBy.organization'], ['entity'], ['timeline', 'subject'], ['timeline', 'subject.organization']], { meta: false });

    populatedResponse.forEach(event => {
      event.timeline.forEach(timelineEvent => {
        FormatHttp.populateBlockchain(response, timelineEvent.blockchain);
      });
    });
    return populatedResponse.map((e: any, index) => {
      e.permissions = response.data[index].meta.perms;
      return e;
    });
  }

  static formatEvent(response: GenericWrapper<any>): EventCard {
    const populatedResponse = FormatHttp.populateResponse<EventCard>(response, [['createdBy'], ['createdBy.organization'], ['entity'], ['timeline', 'subject'], ['timeline', 'subject.organization']], { meta: false });

    populatedResponse.timeline.forEach(timelineEvent => {
      FormatHttp.populateBlockchain(response, timelineEvent.blockchain);
    });
    populatedResponse.permissions = response.data.meta.perms;
    return populatedResponse;
  }
}
